import React from 'react'
import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import {
  useBackButton,
  useBackButtonUpdate,
} from '../../common/context/back-button.context'
import './back-link.scss'

export const BackLink: React.FC<{ centreId?: string }> = ({ centreId }) => {
  const backButton = useBackButton()
  const backButtonUpdate = useBackButtonUpdate()
  const history = useHistory()

  const goBack = () => {
    sessionStorage.clear()
    backButtonUpdate(null)
    if (backButton) {
      history.push(backButton.returnUrl)
    }
  }

  return (
    <>
      {backButton && (
        <div className="back-button" onClick={() => goBack()}>
          <span>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="mr-2 text-secondary"
            />
            <span className="font-weight-bold text-secondary">
              <span className="d-none d-md-inline">{` ${backButton.text}`}</span>
            </span>
          </span>
        </div>
      )}
    </>
  )
}
