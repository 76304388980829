import React from 'react'
import disabledContentForCi from './disabled-content-ci.md'
import disabledContentForOcr from './disabled-content-ocr.md'
import ReactMarkdown from 'react-markdown'

import { isOCR, isCambridgeInternational } from '../../util'

import { faHeartbeat } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HoldingPage = () => (
  <div className="container">
    <div className="holding-page w-100 align-items-center bg-light">
      <FontAwesomeIcon icon={faHeartbeat} size="2x" />
      {isCambridgeInternational() && (
        <ReactMarkdown source={disabledContentForCi} />
      )}
      {isOCR() && <ReactMarkdown source={disabledContentForOcr} />}
    </div>
  </div>
)

export default HoldingPage
