import axios, { AxiosResponse } from 'axios'
import React, { useMemo } from 'react'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { Button, Spinner } from 'reactstrap'
import { useDownloadFile } from '../../../components/use-download-file'
import { PreSignedUploadUrl } from '../../../types'

export const DownloadCentersReportButton: React.FC = (): JSX.Element => {
  const getPresignedUrl = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/download-centre-tags-report`,
    }),
    []
  )

  const getPresignedUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getPresignedUrl)

  const download = useDownloadFile({
    fileUrl: `${getPresignedUrlTask.result?.data.url}`,
    fileName: 'centre-tags-report.csv',
  })

  useAsyncRun(getPresignedUrlTask.result && download)

  const downloadPending = useMemo(() => {
    return (
      (getPresignedUrlTask.pending && getPresignedUrlTask.started) ||
      (getPresignedUrlTask.result != null &&
        download.pending &&
        download.started)
    )
  }, [download, getPresignedUrlTask])

  return (
    <Button color="success" onClick={() => getPresignedUrlTask.start()}>
      {!downloadPending && (
        <FontAwesomeIcon icon={faArrowToBottom} fixedWidth />
      )}
      {downloadPending && <Spinner size="sm" className="mr-2" />}
      <div>Download TAGS report</div>
    </Button>
  )
}
