import React from 'react'
import { Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'
import { useAuth0 } from '../auth'
import { isAdmin, isCAAdmin, isOCR } from '../util'
import { LandingPage } from './landing-page/landing-page'
import { isPast } from 'date-fns';
import { useConfig } from './use-remote-config';
import HoldingPage from './holding-page/holding-page';

export const HomePage: React.FC = (): JSX.Element => {
  const { user, currentCentre } = useAuth0()
  const { config } = useConfig()
  // @ts-ignore
  const canSubmitGrades = config?.submitGradesAvailable > 0 && isPast(config?.submitGradesAvailable || 0);

  if (user && isOCR()) {
    return <LandingPage />
  }

  if (user && isCAAdmin(user)) {
    return <Redirect to="centres" />
  }

  if (user && currentCentre) {
    if (canSubmitGrades) {
      return <Redirect to={`centres/${currentCentre}`} />
    }
    if (isCAAdmin(user)) {
      return <Redirect to={`centres/${currentCentre}/manage/teachers`} />
    }
    if (isAdmin(user)) {
      return <Redirect to="/manage/teachers" />
    }
    return <HoldingPage/>
  }

  return <Container></Container>
}
