import { isFuture, isPast } from 'date-fns'
import { Config } from '../../types'

export class RouterService {
  static isCentresRouteAvailable(
    config: Config,
    systemAvailabilityConfigLoaded: boolean,
    captureAvailableTo?: Date,
    captureAvailableFrom?: Date
  ): boolean {
    const canSubmitGrades =
      config?.submitGradesAvailable &&
      config?.submitGradesAvailable > 0 &&
      isPast(config?.submitGradesAvailable || 0)

    return (
      (captureAvailableFrom &&
        captureAvailableTo &&
        isPast(captureAvailableFrom) &&
        isFuture(captureAvailableTo) &&
        canSubmitGrades) ||
      !systemAvailabilityConfigLoaded
    )
  }
}
