import axios, { AxiosResponse } from 'axios'
import React, { useState, useEffect, useMemo } from 'react'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { useBackButtonUpdate } from '../../common/context/back-button.context'
import { InstructionMaterialDTO } from '../../types'
import { FilePathService } from '../../common/services/file-path.service'
import { QualificationName } from '../../common/enum/qualification-name.enum'
import { GuidanceDocDownload } from '../guidance-doc-download/guidance-doc-download'

import { ResultsCard } from '../results-card/results-card'
import { Loading } from '../loading'

import ReactMarkdown from 'react-markdown'
import qualificationGroupsText from './qualification-groups.md'

interface IQualification {
  title: string
  name: QualificationName
}

export const AdditionalAssessmentMaterial: React.FC = () => {
  const history = useHistory()

  const backButtonUpdate = useBackButtonUpdate()

  useEffect(() => {
    backButtonUpdate({
      returnUrl: '/',
      text: 'Home',
    })
  }, [history.location])

  const [qualifications, setQualifications] = useState<IQualification[]>([])

  const navigateToSpecifications = (qualificationName: QualificationName) =>
    history.push(`/aam/${qualificationName}`)

  const getInstructionMaterialsUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/instruction-materials/aam`,
    }
  }, [])

  const getInstructionMaterials = useAsyncTaskAxios<
    AxiosResponse<InstructionMaterialDTO>
  >(axios, getInstructionMaterialsUrl)

  useAsyncRun(getInstructionMaterials)

  // TODO: replace with actual call
  const getQualificationsAsync: Promise<IQualification[]> = new Promise(
    (resolve, reject) => {
      setTimeout(() => {
        resolve([
          {
            title: 'AS and A Level, Core Maths, FSMQ',
            name: QualificationName.AS_AND_A_LEVEL,
          },
          {
            title: 'GCSE',
            name: QualificationName.GCSE,
          },
          {
            title: 'Cambridge Nationals',
            name: QualificationName.CAMBRIDGE_NATIONALS,
          },
          {
            title: 'Cambridge Technicals',
            name: QualificationName.CAMBRIDGE_TECHNICALS,
          },
        ])
      })
    }
  )

  useEffect(() => {
    getQualificationsAsync.then((quals) => setQualifications(quals))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualifications])

  return qualifications.length ? (
    <Container className="landing-page__container mb-3">
      <Row className="justify-content-center mx-auto">
        <Col>
          <ReactMarkdown source={qualificationGroupsText} />
        </Col>
      </Row>
      <Row className="justify-content-center mx-auto">
        {qualifications.map((q, i) => (
          <Col key={q.title} className="col-12 mt-3">
            <ResultsCard
              title={q.title}
              buttonText="Specifications"
              onClick={() => navigateToSpecifications(q.name)}
              dataTestIdSuffix={`aam-${i + 1}`}
            />
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center mx-auto mt-5">
        <Col>
          <h5 className="landing-page__title">
            Guidance on using additional assessment material
          </h5>
          <p>
            The following guidance will help you use the additional assessment
            materials.
          </p>
        </Col>
      </Row>
      <br />
      {getInstructionMaterials.result &&
        getInstructionMaterials.result.data.materials.map((filePath, i) => (
          <GuidanceDocDownload
            key={filePath}
            fileName={FilePathService.getFileNameFromS3FilePath(filePath)}
            downloadLink={`instruction/download-url?fileName=${FilePathService.encode(
              filePath
            )}`}
            dataTestIdSuffix={`aam-${i + 1}`}
          />
        ))}
    </Container>
  ) : (
    <Container>
      <Loading className="mt-5 d-block mx-auto" />
    </Container>
  )
}
