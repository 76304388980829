import React, { useContext, useEffect, useState } from 'react'
import { isCAAdmin, isCambridgeInternational } from '../../util'
import { useAuth0 } from '../../auth'
import { useHistory } from 'react-router-dom'

interface BackButtonData {
  returnUrl: string
  text: string
}

const BackButtonContext = React.createContext<BackButtonData | null>(null)
const BackButtonUpdateContext = React.createContext<
  (newValue: BackButtonData | null) => void
>(() => null)

export const useBackButton = () => useContext(BackButtonContext)

export const useBackButtonUpdate = () => useContext(BackButtonUpdateContext)

export const BackButtonProvider: React.FC = ({ children }) => {
  const [backButtonData, setBackButtonData] = useState<BackButtonData | null>(
    null
  )

  const { user } = useAuth0()
  const history = useHistory()

  const setBackButton = (newButtonData: BackButtonData | null) => {
    setBackButtonData(newButtonData)
  }

  useEffect(() => {
    if (backButtonData) {
      return
    }

    const homeUrl = '/centres'

    if (
      isCambridgeInternational() &&
      user &&
      isCAAdmin(user) &&
      history.location.pathname !== homeUrl
    ) {
      setBackButtonData({
        returnUrl: homeUrl,
        text: 'Home',
      })
    }
  }, [history.location])

  return (
    <BackButtonContext.Provider value={backButtonData}>
      <BackButtonUpdateContext.Provider value={setBackButton}>
        {children}
      </BackButtonUpdateContext.Provider>
    </BackButtonContext.Provider>
  )
}
