import React, { useMemo } from 'react'
import { Col, Row, Spinner } from 'reactstrap'

interface IGradeSelector {
  value?: string
  pendingValue?: string
  options: string[]
  disabled: boolean
  onChange: (value: string) => void
}
export const GradeSelector: React.FC<IGradeSelector> = ({
  value,
  pendingValue,
  options,
  disabled,
  onChange,
}): JSX.Element => {
  const isPending = useMemo(
    () => pendingValue && options.includes(pendingValue),
    [pendingValue, options]
  )

  const classnames = useMemo(
    () => (grade: string) => {
      return Object.entries({
        disabled,
        active: value === grade,
        pending: isPending && pendingValue === grade,
        pointer: !isPending && !disabled,
      }).reduce((acc, [key, check]) => acc + (check ? `${key} ` : ''), '')
    },
    [disabled, value, pendingValue, isPending]
  )

  return (
    <Row className={`grade-selector ${isPending ? 'grade-pending' : ''}`}>
      {options.map((it, idx) => (
        <Col
          key={`grade-option-${idx}`}
          className={`grade noselect ${classnames(it)}`}
          onClick={() => {
            !isPending && !disabled && onChange(it)
          }}
        >
          {pendingValue === it ? <Spinner size="sm" color="white" /> : it}
        </Col>
      ))}
    </Row>
  )
}
