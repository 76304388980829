import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { CentreSyllabus, SyllabusCandidate } from '../../../types'
import {
  candidateColumnSizes,
  ICandidateUpdate,
  UNRANKED_GRADE,
} from '../constants'
import { GradeSelector } from '../utilities/grade-selector'
import { PathwaySelector } from '../utilities/pathway-selector/pathway-selector'

interface ICandidateGradingFull {
  candidate: SyllabusCandidate
  grading: string[]
  candidatesInGrade: number
  patchingProgress?: string
  onChange: (update: ICandidateUpdate, cb?: (good: boolean) => void) => void
  isErroneous: boolean
  viewOnly: boolean
  availablePaths: string[]
  syllabus: CentreSyllabus
  pathwaysUpdating: boolean
}

export const CandidateGrading: React.FC<ICandidateGradingFull> = ({
  candidate: {
    id,
    centreSyllabusId,
    candidateNumber,
    name,
    dob,
    grade,
    allowedGrades,
    lastUpdated,
    pathwayOne,
    pathwayTwo,

    // ** feature/ISPR-1517 hide ties and ranks **
    // rank
  },
  grading,
  candidatesInGrade,
  patchingProgress,
  isErroneous,
  viewOnly,
  onChange,
  availablePaths,
  syllabus,
  pathwaysUpdating,
}): JSX.Element => {
  const canSelectPathways = useMemo(() => !!syllabus.paths?.length, [
    syllabus.paths,
  ])

  const amountColumnsWithPathway = 3
  const amountColumnsWithoutPathway = 2

  const columnCount = useMemo(
    () =>
      canSelectPathways
        ? amountColumnsWithPathway
        : amountColumnsWithoutPathway,
    [canSelectPathways]
  )
  const sizes = useMemo(() => candidateColumnSizes[columnCount], [columnCount])

  const [pendingGrade, setPendingGrade] = useState<string | undefined>()

  useEffect(() => {
    if (!patchingProgress) {
      setPendingGrade(undefined)
    }
  }, [patchingProgress, setPendingGrade])

  return (
    <Row
      className={
        isErroneous
          ? 'reddish-background candidate-row px-25 py-35'
          : 'candidate-row px-25 py-35'
      }
    >
      <Col className="text-left name" md={sizes[0]}>
        <Label className="candidate-name mb-1">{name}</Label>
        <Label className="candidate-uln mb-0">{candidateNumber}</Label>
      </Col>
      <Col className="d-flex justify-content-center" md={sizes[1]}>
        <GradeSelector
          value={grade}
          options={allowedGrades}
          pendingValue={
            patchingProgress === 'pending' ? pendingGrade : undefined
          }
          disabled={viewOnly}
          onChange={(val: string) => {
            setPendingGrade(val)

            // ** feature/ISPR-1517 hide ties and ranks **
            // onChange({ grade: val, rank })

            onChange({ pathwayOne, pathwayTwo, grade: val })
          }}
        />
      </Col>
      {canSelectPathways &&
        (grade !== UNRANKED_GRADE ? (
          <Col className="text-center" md={sizes[2]}>
            <PathwaySelector
              availablePaths={availablePaths}
              pathwayOne={pathwayOne}
              viewOnly={viewOnly}
              pathwayTwo={pathwayTwo}
              isPendingPatch={!!patchingProgress && pathwaysUpdating}
              onChange={(changes) => {
                onChange({ ...changes, grade })
              }}
            />
          </Col>
        ) : (
          <Col className="text-center">-</Col>
        ))}

      {/** feature/ISPR-1517 hide ties and ranks **/}
      {/* <Col className="text-center text-body font-weight-bold" md={sizes[2]}>
        <Label>{rank || '–'}</Label>
      </Col> */}
    </Row>
  )
}
