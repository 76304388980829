import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import axios, { AxiosResponse } from 'axios'
import React, { FC, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { Button, Spinner } from 'reactstrap'
import { DataSource } from '../../../../common/enum/data-source'
import { useDownloadFile } from '../../../../components/use-download-file'
import { PreSignedUploadUrl } from '../../../../types'
import './download-pathways.scss'

export const DownloadPathwaysButton: FC<{
  centreId: string
  syllabusId: string
  dataSource: string
}> = ({ centreId, syllabusId, dataSource }) => {
  const getPresignedUrl = useMemo(() => {
    if (centreId && syllabusId) {
      return {
        url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/pathwaysguide`,
      }
    }
    return null
  }, [centreId, syllabusId])

  const getPresignedUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getPresignedUrl)

  const download = useDownloadFile({
    fileUrl: `${getPresignedUrlTask.result?.data.url}`,
    fileName: 'Pathways.csv',
  })

  useAsyncRun(getPresignedUrlTask.result && download)

  const handleDownload = () => {
    getPresignedUrlTask.start()
  }

  const downloadPending = useMemo(() => {
    return (
      (getPresignedUrlTask.pending && getPresignedUrlTask.started) ||
      (getPresignedUrlTask.result != null &&
        download.pending &&
        download.started)
    )
  }, [download, getPresignedUrlTask])

  if (dataSource !== DataSource.CAMS) {
    return null
  }

  return (
    <Button
      color="e4"
      className="mr-3 text-center text-secondary download-pathways"
      onClick={handleDownload}
    >
      {!downloadPending && (
        <>
          <FontAwesomeIcon icon={faArrowToBottom} fixedWidth />
          <div>Download Pathways</div>
        </>
      )}
      {downloadPending && <Spinner size="sm" className="mr-2" />}
    </Button>
  )
}
