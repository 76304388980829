import React, { useMemo, useState } from 'react'
import { Col, Row, Spinner, Button } from 'reactstrap'
import { formatDate } from '../constants'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchBar } from '../../search-bar/search-bar'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'

const UpdatingLabel: React.FC<{ updating: boolean }> = ({
  updating,
}): JSX.Element => (
  <Button color="primary" disabled>
    {updating ? (
      <Spinner size="sm" className="mr-2" />
    ) : (
      <FontAwesomeIcon icon={faCheck} className="mr-2" />
    )}
    {updating ? 'Saving' : 'Auto-saved'}
  </Button>
)

interface ICandidatesSearchLine {
  lastUpdated: number
  upToDate: boolean
  viewOnly: boolean
  filterChanged: (val: string) => void
  checkForErrorsClick: () => void
  downloadCallback: () => void
  approvalDisabled: boolean
  openSetXGradeModal: () => void
  isShowSetXGradeButton: boolean
}

export const CandidatesSearchLine: React.FC<ICandidatesSearchLine> = ({
  approvalDisabled,
  lastUpdated,
  checkForErrorsClick,
  viewOnly,
  upToDate,
  filterChanged,
  downloadCallback,
  openSetXGradeModal,
  isShowSetXGradeButton,
}): JSX.Element => {
  const lastUpdatedString = useMemo(
    () => formatDate(new Date(lastUpdated), true, true).replace(/\//g, ' / '),
    [lastUpdated]
  )

  const [searchInput, setSearchInput] = useState('')

  const setInput = (val: string = '') => {
    setSearchInput(val)
    filterChanged(val.trim())
  }

  return (
    <Row className="search-line mb-5 align-items-center">
      <Col md={10}>
        <SearchBar
          val={searchInput}
          placeholder="Search for a candidate"
          filterChanged={(newVal) => setInput(newVal)}
          disableSearch={searchInput.length < 5}
        />
      </Col>
      {!viewOnly && (
        <>
          <Col className="text-right last-updated">
            {!!lastUpdated && (
              <>
                <div>Last updated on</div>
                <div>{lastUpdatedString}</div>
              </>
            )}
          </Col>
          <Col className="text-right" xs="auto">
            <UpdatingLabel updating={!upToDate} />
          </Col>
          <Col className="text-right" xs="auto">
            {isShowSetXGradeButton && (
              <Button
                disabled={!upToDate}
                className="mr-3"
                color="primary"
                onClick={() => openSetXGradeModal()}
              >
                Set list as complete
              </Button>
            )}
            <Button color="success" onClick={checkForErrorsClick}>
              {approvalDisabled ? (
                'Check for errors'
              ) : (
                <div>
                  <FontAwesomeIcon icon={faCheck} className="mr-2" />
                  No errors found
                </div>
              )}
            </Button>
          </Col>
        </>
      )}
      {viewOnly && (
        <Col className="text-right">
          <Button
            color="e4"
            className="mr-3 text-center text-secondary"
            onClick={() => {
              downloadCallback()
            }}
          >
            <FontAwesomeIcon icon={faArrowToBottom} fixedWidth />
            <div>Download Template</div>
          </Button>
        </Col>
      )}
    </Row>
  )
}
