import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { countBy, keys, sortBy } from 'lodash'
import React, { useMemo } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { TextAlign } from '../../../../common/enum/text-align.enum'
import getTextFromToken from '../../../../tokenised-text'
import { SyllabusCandidate } from '../../../../types'
import { candidateColumnSizes } from '../../constants'
import {
  ColumnMap,
  ICandidatesSorter,
  ISorter,
  SortableColumn,
  SortableColumnMap,
} from './types'

const SORTABLE_COLUMN: SortableColumnMap = {
  NAME: {
    title: 'Candidates (A-Z)',
    titleAlt: 'Candidates (Z-A)',
    sortable: true,
    comp: (opts) => (a, b) => {
      const diff = a.name.localeCompare(b.name)
      return diff === 0 ? SORTABLE_COLUMN.ULN.comp(opts)(a, b) : diff
    },
  },
  DOB: {
    title: 'DOB',
    sortable: true,
    comp: (opts) => (a, b) => {
      const diff = Date.parse(b.dob) - Date.parse(a.dob)
      return diff === 0 ? SORTABLE_COLUMN.NAME.comp(opts)(a, b) : diff
    },
  },
  ULN: {
    title: 'Candidate number',
    sortable: true,
    comp: () => (a, b) => b.candidateNumber.localeCompare(a.candidateNumber),
  },
  GRADE: {
    title: getTextFromToken('assessedGrades'),
    sortable: true,
    comp: (opts) => (a, b) => {
      const diff =
        (a.grade ? opts.grading.indexOf(a.grade) : 1000) -
        (b.grade ? opts.grading.indexOf(b.grade) : 1000)
      return diff === 0 ? SORTABLE_COLUMN.NAME.comp(opts)(a, b) : diff
    },
  },
  // ** feature/ISPR-1517 hide ties and ranks **
  // RANKING: {
  //   title: 'Rank order',
  //   sortable: true,
  //   comp: (opts) => (a, b) => {
  //     const diff = (a.rank || 1000) - (b.rank || 1000)
  //     return diff === 0 ? COLUMNS.GRADE.comp(opts)(a, b) : diff
  //   },
  // },
}

const COLUMNS_DEFAULT: ColumnMap = {
  NAME: SORTABLE_COLUMN.NAME,
  GRADE: SORTABLE_COLUMN.GRADE,
  // ** feature/ISPR-1517 hide ties and ranks **
  // RANKING: {
  //   ...COLUMNS.RANKING,
  //   sortable: false
  // }
}

export const toggleSorting = (
  sorting: string,
  currentSorting: string
): string => {
  const [curr, direction] = currentSorting.split('-')
  const invDirection = direction === 'up' ? 'down' : 'up'
  return sorting !== curr ? `${sorting}-down` : `${curr}-${invDirection}`
}

export const candidatesSorting = (
  candidates: SyllabusCandidate[],
  sorting: string,
  options: { grading: string[] }
): string[] => {
  const [sortingKey, direction] = sorting.split('-')
  const { comp } = SORTABLE_COLUMN[sortingKey] as SortableColumn
  const sorted = [...candidates].sort(comp(options)).map((it) => it.id)

  if (sortingKey === 'GRADE') {
    let arrToChange = [...candidates].sort(comp(options))
    let grades = countBy(arrToChange, (x) => x.grade)
    let res: string[] = []
    let ungradedStudents = arrToChange
      .filter((d) => d.grade === undefined)
      .map((e) => e.id)
    keys(grades).forEach((x) => {
      // ** feature/ISPR-1517 hide ties and ranks **
      // let sortedIds = sortBy(arrToChange.filter((y) => y.grade === x), (z) => z.rank).map(e => e.id)
      let sortedIds = sortBy(arrToChange.filter((y) => y.grade === x)).map(
        (e) => e.id
      )
      direction === 'up'
        ? sortedIds.forEach((id) => res.unshift(id))
        : sortedIds.forEach((id) => res.push(id))
    })
    direction === 'up'
      ? (res = ungradedStudents.concat(res))
      : (res = res.concat(ungradedStudents))
    return res
  }
  return direction === 'up' ? sorted.reverse() : sorted
}

const SortCell: React.FC<ISorter> = ({
  title,
  toggle,
  textAlign,
  upDownActive,
  sortable,
  size,
}): JSX.Element => {
  const inactiveColor = {
    color: '#d4d4d4',
  }

  const iconProps = {
    up: upDownActive === 'up' ? {} : inactiveColor,
    down: upDownActive === 'down' ? {} : inactiveColor,
  }

  return (
    <Col className={`text-${textAlign}`} md={size}>
      <Label
        onClick={toggle}
        className={`sorter-label noselect text-primary bg-none px-0 my-0 ${
          sortable ? 'sortable' : ''
        }`}
      >
        {title}

        {sortable && (
          <span>
            <FontAwesomeIcon
              icon={faSortUp}
              {...iconProps.up}
              className={`sort-up ml-2`}
            />
            <FontAwesomeIcon
              icon={faSortDown}
              {...iconProps.down}
              className={`ml-2`}
            />
          </span>
        )}
      </Label>
    </Col>
  )
}

export const CandidatesSorter: React.FC<ICandidatesSorter> = ({
  currentSorting,
  filter,
  showBottomBorder,
  toggleSorting,
  syllabus,
}): JSX.Element => {
  const sorting: ColumnMap = useMemo(
    () => ({
      ...(filter === 'ALL' ? COLUMNS_DEFAULT : SORTABLE_COLUMN),
      ...(!!syllabus.paths?.length && {
        PATHWAY: {
          title: `Pathway ${syllabus.pathwayMandatory ? '' : '(optional)'}`,
        },
      }),
    }),
    [filter, syllabus.paths, syllabus.pathwayMandatory]
  )

  const [key, direction] = currentSorting.split('-')
  const alignSortCellText = (index: number): TextAlign => {
    if (index === 0) return TextAlign.LEFT
    else return TextAlign.CENTER
  }

  return (
    <Row
      className={`sorter-container px-25 py-25 ${
        showBottomBorder ? 'border-bottom' : ''
      }`}
    >
      {Object.keys(sorting).map((it, idx) => {
        const title =
          direction === 'up' && key === it
            ? sorting[it].titleAlt || sorting[it].title
            : sorting[it].title

        const upDownActive = key === it ? direction : ''

        return (
          <SortCell
            key={`candidate-sorter-${it}`}
            title={title}
            textAlign={alignSortCellText(idx)}
            upDownActive={upDownActive}
            sortable={!!sorting[it].sortable}
            size={
              (candidateColumnSizes[Object.keys(sorting).length] || [])[idx]
            }
            toggle={() => sorting[it].sortable && toggleSorting(it)}
          />
        )
      })}
    </Row>
  )
}
