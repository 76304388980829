import { useMemo } from 'react'
import { isFuture, isPast } from 'date-fns/esm'

import { useConfig } from './use-remote-config'

export const useAvailability = () => {
  const { config } = useConfig()

  const {
    captureAvailableFrom,
    captureAvailableTo,
    aamAvailableFrom,
    aamAvailableTo,
  } = config

  const aamManagementAvailable = useMemo(
    () =>
      aamAvailableFrom &&
      isPast(aamAvailableFrom) &&
      aamAvailableTo &&
      isFuture(aamAvailableTo),
    [aamAvailableFrom, aamAvailableTo]
  )

  const userManagamentAvailable = useMemo(
    () =>
      captureAvailableFrom &&
      isPast(captureAvailableFrom) &&
      captureAvailableTo &&
      isFuture(captureAvailableTo),

    [captureAvailableFrom, captureAvailableTo]
  )

  return [aamManagementAvailable, userManagamentAvailable]
}
