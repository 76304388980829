import React, { useMemo } from 'react'
import { Col, Row } from 'reactstrap'
import { CentreSyllabus, SyllabusCandidate } from '../../../types'
import {
  candidateColumnSizes,
  ICandidateUpdate,
  UNRANKED_GRADE,
} from '../constants'
import { PathwaySelector } from '../utilities/pathway-selector/pathway-selector'

// ** feature/ISPR-1517 hide ties and ranks **
// import {
//   candidateColumnSizes,
//   ICandidateUpdate,
//   UNRANKED_GRADES,
// } from '../constants'
// import { RankSelector } from '../utilities/rank-selector'

interface ICandidateFull {
  idx: number
  candidate: SyllabusCandidate
  grading: string[]
  candidatesInGrade: number
  isErroneous: boolean
  viewOnly: boolean
  patchingProgress?: string
  onChange: (update: ICandidateUpdate, cb?: (good: boolean) => void) => void
  availablePaths: string[]
  syllabus: CentreSyllabus
  pathwaysUpdating: boolean
}

export const CandidateFull: React.FC<ICandidateFull> = ({
  idx,
  candidate: {
    id,
    centreSyllabusId,
    candidateNumber,
    name,
    dob,
    grade,
    allowedGrades,
    lastUpdated,
    pathwayOne,
    pathwayTwo,

    // ** feature/ISPR-1517 hide ties and ranks **
    // rank
  },
  grading,
  isErroneous,
  viewOnly,
  candidatesInGrade,
  patchingProgress,
  onChange,
  availablePaths,
  syllabus,
  pathwaysUpdating,
}): JSX.Element => {
  const canSelectPathways = useMemo(() => !!syllabus.paths?.length, [
    syllabus.paths,
  ])
  const amountColumnsWithPathway = 5
  const amountColumnsWithoutPathway = 4

  const columnCount = useMemo(
    () =>
      canSelectPathways
        ? amountColumnsWithPathway
        : amountColumnsWithoutPathway,
    [canSelectPathways]
  )
  const sizes = useMemo(() => candidateColumnSizes[columnCount], [columnCount])

  return (
    <Row
      className={
        isErroneous
          ? 'reddish-background candidate-row px-25 py-3'
          : 'candidate-row px-25 py-3'
      }
    >
      <Col className="text-left name" md={sizes[0]}>
        {name}
      </Col>
      <Col className="text-center font-normal font-weight-bold" md={sizes[1]}>
        {dob}
      </Col>
      <Col className="text-center font-normal font-weight-bold" md={sizes[2]}>
        {candidateNumber}
      </Col>
      <Col className="text-center" md={sizes[3]}>
        <span className="candidate-grade px-3 py-25">{grade}</span>
      </Col>
      {canSelectPathways &&
        (grade !== UNRANKED_GRADE ? (
          <Col className="text-right pr-2" md={sizes[4]}>
            <PathwaySelector
              availablePaths={availablePaths}
              pathwayOne={pathwayOne}
              pathwayTwo={pathwayTwo}
              viewOnly={viewOnly}
              onChange={(e) =>
                onChange({
                  grade,
                  pathwayOne: e.pathwayOne,
                  pathwayTwo: e.pathwayTwo,
                })
              }
              isPendingPatch={!!patchingProgress && pathwaysUpdating}
            />
          </Col>
        ) : (
          <Col className="text-center">-</Col>
        ))}
      {/** feature/ISPR-1517 hide ties and ranks **/}
      {/* <Col className="text-center" md={sizes[4]}>
        {(!viewOnly && !(UNRANKED_GRADES.includes(grade || ''))) ? (
          <RankSelector
            tabIndex={idx}
            value={rank}
            max={candidatesInGrade}
            disabled={patchingProgress === 'pending'}
            onChange={(val: number, cb?: (good: boolean) => void) => {
              onChange({ grade: grade || '', rank: val }, cb)
            }}
          />
        ) : (
          <Label className="font-weight-bold">{rank || '–'}</Label>
        )}
        {patchingProgress === 'pending' && (
          <div style={{ position: 'absolute', right: '-8px', top: '5px' }}>
            <Spinner size="sm" color="secondary" />
          </div>
        )}
      </Col> */}
    </Row>
  )
}
