import React, { useEffect, useMemo, useState } from 'react'

import { Button, Col, Row, Spinner } from 'reactstrap'
import { RsModalHeader } from '../modal/subcomponents/modal-header'
import { RsModalBody } from '../modal/subcomponents/modal-body'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProjectModal } from '../modal/modal'
import { Title } from '../title/title'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { ROLES, isOCR, isCambridgeInternational } from '../../util'

interface IAddUserModalData {
  name: string
  surname: string
  email: string
  role: string
  centreId: string
  userRole?: string
  userCentres?: string[]
}

export enum AddUserEvents {
  SAME_CENTRE_SAME_ROLE,
  SAME_CENTRE_NEW_ROLE,
  MULTIPLE_CENTRES_SAME_ROLE,
  MULTIPLE_CENTRES_NEW_ROLE,
}
const EVENT_MAP = {
  [AddUserEvents.SAME_CENTRE_SAME_ROLE]: (data: IAddUserModalData) => ({
    title: isOCR()
      ? 'This user already exists'
      : 'Adding an already existing user',
    description: isOCR() ? (
      <div>
        This user already has the role of <b>{data.userRole}</b> at this centre.{' '}
        You can resend their invitation in the managing users area.
      </div>
    ) : (
      <div>
        This user already has the role of <b>{data.userRole}</b> at this centre
      </div>
    ),
    subDescription: isCambridgeInternational() && (
      <div className="mt-1">
        You can resend the invitation in the user management page.
      </div>
    ),
    confirmText: null,
    cancelText: isOCR() ? 'OK' : 'Cancel',
  }),
  [AddUserEvents.SAME_CENTRE_NEW_ROLE]: (data: IAddUserModalData) => ({
    title: `Changing ${data.userRole} role to ${data.role}`,
    description: (
      <div>
        This user currently has the <b>{data.userRole}</b> role in this centre.
        {data.userRole === ROLES.HOC
          ? ' Without the Head of Centre role, you will not be able to submit grades to us.'
          : ''}
      </div>
    ),
    subDescription: (
      <u className="d-block">{`${
        isOCR()
          ? 'Please confirm you want to change their role to Teacher / Additional Assessment Materials'
          : 'Please confirm if you wish to proceed'
      }.`}</u>
    ),
    confirmText: 'Yes, change role',
    cancelText: 'Cancel',
  }),
  [AddUserEvents.MULTIPLE_CENTRES_SAME_ROLE]: (data: IAddUserModalData) => ({
    title: `Adding a new centre (${data.centreId}) for user`,
    description: (
      <div>
        This user already has the <b>{data.userRole}</b> role in the following{' '}
        centres: {(data.userCentres || []).join(', ')}.
      </div>
    ),
    subDescription: isOCR() ? (
      <u className="d-block">Please confirm you want to add a new centre.</u>
    ) : (
      <u className="d-block">Please confirm if you wish to proceed.</u>
    ),
    confirmText: 'Yes, add centre',
    cancelText: 'Cancel',
  }),
  [AddUserEvents.MULTIPLE_CENTRES_NEW_ROLE]: (data: IAddUserModalData) => ({
    title: isOCR()
      ? 'Users can only have one role'
      : `Changing role to ${data.role} for user with multiple centres`,
    description: isOCR() ? (
      <div>
        This user already has the <b>{data.userRole}</b> role in the following
        centres: {(data.userCentres || []).join(', ')}. You cannot change their
        role to <b>{data.role} </b> at this centre.
      </div>
    ) : (
      <div>
        This user has the role <b>{data.userRole}</b> in the following centres:{' '}
        {(data.userCentres || []).join(', ')}.
      </div>
    ),
    subDescription: isCambridgeInternational() && (
      <div className="mt-1">
        This user cannot have the role <b>{data.role} </b>
        assigned in selected centre (either delete and recreate all users with
        wanted role in all centres or contact support).
      </div>
    ),
    confirmText: null,
    cancelText: 'Cancel',
  }),
}

interface IAddUserModal {
  show: boolean
  close: () => void
  onSubmit: () => void
  addUserInProgress: boolean
  addUserError: null | unknown
  event: AddUserEvents
  data?: IAddUserModalData
}
export const AddUserModal: React.FC<IAddUserModal> = ({
  show,
  close,
  onSubmit,
  event: paramEvent,
  addUserInProgress,
  addUserError,
  data: paramData,
}) => {
  const [data, setData] = useState<IAddUserModalData|undefined>(paramData)
  const [event, setEvent] = useState<AddUserEvents>(paramEvent)

  useEffect(() => {
    if (!show) {
      const t = setTimeout(() => {
        setData(paramData)
        setEvent(paramEvent)
      }, 1000)
      return () => clearTimeout(t)
    } else {
      setData(paramData)
      setEvent(paramEvent)
    }
  }, [paramData, paramEvent, show])

  const { name, surname, email } = data || {}
  const textMap = useMemo(() => data && EVENT_MAP[event](data), [event, data])

  return (
    <ProjectModal
      className={`remove-user-modal ${addUserInProgress ? 'disabled' : ''}`}
      centered
      isOpen={show}
    >
      <RsModalHeader
        toggle={() => !addUserInProgress && close()}
        className="bg-white px-5 pt-5 pb-25"
      >
        <Title title={`${name || ''} ${surname || ''}`} subTitle={email || ''} />
      </RsModalHeader>
      <RsModalBody className="pt-4 px-5 pb-5 rounded-bottom">
        <div>
          <p className="font-weight-bold font-larger">{textMap?.title}</p>
          <p className="font-normal">
            {textMap?.description}
            {textMap?.subDescription}
          </p>
          {addUserError && (
            <div className="text-danger font-weigh-bold">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" />
              <span>Failed to add user, please try again</span>
            </div>
          )}
          <Row className="mt-5 ">
            {textMap?.cancelText && (
              <Col xs="auto">
                <Button color="e4" disabled={addUserInProgress} onClick={close}>
                  {textMap.cancelText}
                </Button>
              </Col>
            )}
            {textMap?.confirmText !== null && (
              <Col>
                <Button
                  onClick={() => {
                    onSubmit()
                    close()
                  }}
                  disabled={addUserInProgress}
                  color="primary"
                >
                  {addUserInProgress ? (
                    <Spinner size="sm" className="mr" />
                  ) : (
                    <span>{textMap?.confirmText}</span>
                  )}
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </RsModalBody>
    </ProjectModal>
  )
}
