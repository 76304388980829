import { useEffect, useMemo, useState } from 'react'
import { useAsyncRun, useAsyncTaskAxios, AsyncTask } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'

import { ManagedUser } from '../../types'
import { FilePathService } from '../../common/services/file-path.service';

const buildRequest = (centreId: string, method?: string, body?: string) => ({
  url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/users`,
  headers: {
    Accept: 'application/json',
  },
  method,
  body,
})

export const useUserManagement = (
  centreId: string
): [
  ManagedUser[],
  AsyncTask<AxiosResponse<ManagedUser>>,
  AsyncTask<AxiosResponse<ManagedUser[]>>,
  (email: string) => Promise<ManagedUser | null>,
  (email: string) => void
] => {
  const [userList, setUserList] = useState<ManagedUser[]>([])

  const getUsersMemo = useMemo(() => buildRequest(centreId), [centreId])
  const getUsersTask = useAsyncTaskAxios<AxiosResponse<ManagedUser[]>>(
    axios,
    getUsersMemo
  )

  useAsyncRun(getUsersTask)

  const createUserMemo = useMemo(() => buildRequest(centreId, 'POST'), [
    centreId,
  ])
  const createUserTask = useAsyncTaskAxios<AxiosResponse<ManagedUser>>(
    axios,
    createUserMemo
  )

  useEffect(() => {
    if (createUserTask.result) {
      setUserList((oldList) => [
        ...oldList.filter(
          (user) => user.email !== createUserTask.result.data.email
        ),
        createUserTask.result.data,
      ])
    }
  }, [createUserTask.result])

  useEffect(() => {
    setUserList((getUsersTask.result || {}).data || [])
  }, [getUsersTask.result])

  // Intercept getUserByEmail 404 error
  useEffect(() => {
    const interceptor = axios.interceptors.response.use((response) => {
      return response;
    }, function (error) {
      const isGetUserByEmailUrl =/.*\/users\/[a-zA-Z0-9+/=]+$/.test(error.config?.url)
      if (isGetUserByEmailUrl && error.request?.status === 404) {
        return Promise.resolve(null);
      }
      return Promise.reject(error.response);
    });
    return () => {
      axios.interceptors.response.eject(interceptor)
    }
  }, [])

  const getUserByEmail = useMemo(() => async (email: string): Promise<ManagedUser | null> => {
    const encodedEmail = FilePathService.encode(email)
    const response = await axios.get(`${process.env.REACT_APP_APIDOMAIN}/users/${encodedEmail}`, {
      headers: {
        Accept: 'application/json',
      },
    })
    return response?.data || null
  }, [])

  const deleteUser = (email: string) => {
    setUserList((oldList) => [
      ...oldList.filter((user) => user.email !== email),
    ])
  }

  return [userList, createUserTask, getUsersTask, getUserByEmail, deleteUser]
}
