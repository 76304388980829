import React, { useMemo } from 'react'
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Col,
  Spinner,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import './pathway-selector.scss'
import { ICandidateUpdate } from '../../../candidates-list/constants'

interface PathwayData {
  pathway: string
  isSelected: boolean
}

export const PathwaySelector: React.FC<{
  availablePaths: string[]
  pathwayOne: string
  pathwayTwo: string
  isPendingPatch: boolean
  viewOnly: boolean
  onChange: (value: ICandidateUpdate) => void
}> = ({
  availablePaths,
  pathwayOne,
  pathwayTwo,
  isPendingPatch,
  viewOnly,
  onChange,
}) => {
  const selectablePathways = useMemo(
    () =>
      [pathwayOne, pathwayTwo].reduce((res: string[], path) => {
        if (path) {
          res.push(path)
        }
        return res
      }, []),
    [pathwayOne, pathwayTwo]
  )

  const availableAndSelectedPathways: PathwayData[] = useMemo(() => {
    const availablePathsWithoutSelected = availablePaths.filter(
      (path) => path !== pathwayOne && path !== pathwayTwo
    )
    return [
      ...selectablePathways.map((pathway) => ({ pathway, isSelected: true })),
      ...availablePathsWithoutSelected.map((pathway) => ({
        pathway,
        isSelected: false,
      })),
    ]
  }, [availablePaths, selectablePathways])

  const addSelectedPathway = (pathwayData: PathwayData): void => {
    const canAddSelectedPathway = selectablePathways.length < 2

    if (canAddSelectedPathway) {
      const newSelectedPathwaysArray = [
        ...selectablePathways,
        pathwayData.pathway,
      ]
      const updatedData = getNewSelectedPathways(newSelectedPathwaysArray)
      onChange(updatedData)
    }
  }

  const removeSelectedPathway = (pathway: string): void => {
    const newSelectedPathwaysArray = selectablePathways.filter(
      (pWay) => pWay !== pathway
    )
    const updatedData = getNewSelectedPathways(newSelectedPathwaysArray)
    onChange(updatedData)
  }

  const getNewSelectedPathways = (
    newSelectedPathwaysArray: string[]
  ): { pathwayOne?: string; pathwayTwo?: string } => {
    return newSelectedPathwaysArray.reduce((res: any, pathway, i) => {
      const pathwayField = getPathwayByIndex(i)
      if (pathwayField) {
        res[pathwayField] = pathway
      }
      return res
    }, {})
  }

  const getPathwayByIndex = (index: number): string | null => {
    switch (index) {
      case 0:
        return 'pathwayOne'
      case 1:
        return 'pathwayTwo'
    }
    return null
  }

  const handlePathwaySelection = (pathwayData: PathwayData): void =>
    pathwayData.isSelected
      ? removeSelectedPathway(pathwayData.pathway)
      : addSelectedPathway(pathwayData)

  if (isPendingPatch) {
    return (
      <Row className="align-items-center justify-content-center">
        <Col className="d-flex justify-content-end pr-5">
          <Spinner className="spinner" size="sm" />
        </Col>
      </Row>
    )
  }

  if (
    availableAndSelectedPathways.length === 0 ||
    (viewOnly && selectablePathways.length === 0)
  ) {
    return <div className="pathways-unavailable">-</div>
  }

  return (
    <UncontrolledDropdown className="pathway-selector-dropdown">
      <Row>
        <Col md="20" className="patways-selection-container">
          {selectablePathways.length === 0 ? (
            <p className="pathways-unselected-text">
              {'Select up to 2 pathways'}
            </p>
          ) : (
            selectablePathways.map((pathway) => (
              <p className="pathways-text" key={pathway}>
                {pathway}
              </p>
            ))
          )}
        </Col>
        {!viewOnly && (
          <Col md="4" className="patways-icon-container">
            <DropdownToggle nav data-testid="pathway-toggle">
              <FontAwesomeIcon
                className="d-inline-block pathways-icon"
                icon={faCaretDown}
              />
            </DropdownToggle>
          </Col>
        )}
      </Row>
      <DropdownMenu right>
        {availableAndSelectedPathways.map((pathwayData) => (
          <DropdownItem
            className={pathwayData.isSelected ? 'selected-pathway' : ''}
            key={pathwayData.pathway}
            onClick={() => handlePathwaySelection(pathwayData)}
          >
            {pathwayData.isSelected ? (
              <Col md="4">
                <FontAwesomeIcon
                  className="d-inline-block pathways-icon"
                  icon={faCheck}
                />
              </Col>
            ) : (
              <Col md="4"> </Col>
            )}
            <Col md="20">{pathwayData.pathway}</Col>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}
