import React from 'react'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spinner } from 'reactstrap'

interface IDownloadButton {
  text: string
  className?: string
  color?: string
  download: () => void
  pending: boolean
}

export const DownloadButton: React.FC<IDownloadButton> = ({
  text,
  className,
  color = 'success',
  download,
  pending,
}): JSX.Element => (
  <Button
    color={color}
    className={className}
    disabled={pending}
    onClick={download}
  >
    {pending ? (
      <Spinner size="sm" className="mr-2" />
    ) : (
      <FontAwesomeIcon icon={faArrowToBottom} fixedWidth />
    )}
    <div>{text}</div>
  </Button>
)
