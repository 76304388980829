import React from 'react'
import { generatePath, Redirect } from 'react-router-dom';
import { Route as ReactRoute } from 'react-router';
import { NotFound } from './not-found';
import { Auth0Context } from '../auth';
import { isCAAdmin } from '../util';
import { User } from '../types';

class Route extends ReactRoute {

  checkForRedirectToUpperCaseCentreId() {
    // @ts-ignore
    const match = this.props.computedMatch;
    const centreId = match?.params?.id;
    if (centreId && centreId !== centreId.toUpperCase()) {
      const path = generatePath(match.path, { ...match.params, id: centreId.toUpperCase() });
      return <Redirect to={path}/>
    }
  }

  checkForDifferentCentreAccess({ currentCentre, user }: { currentCentre: string, user: User }) {
    // @ts-ignore
    const match = this.props.computedMatch;
    const centreId = match?.params?.id;

    if (user && currentCentre && centreId && centreId !== currentCentre && !isCAAdmin(user)) {
      return <NotFound />
    }
  }

  checkRules(auth0Context: any) {
    const rule1 = this.checkForRedirectToUpperCaseCentreId();
    if (rule1) return rule1;

    const rule2 = this.checkForDifferentCentreAccess(auth0Context);
    if (rule2) return rule2;
  }

  render() {
    return <Auth0Context.Consumer>
      {(auth0Context: any) => {
        const renderedByRule = this.checkRules(auth0Context)
        return renderedByRule || super.render();
      }}
    </Auth0Context.Consumer>
  }
}

export default React.memo(Route);
