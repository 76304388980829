import { faCloudDownloadAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios, { AxiosResponse } from 'axios'
import React, { FC, useMemo } from 'react'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { Button, Spinner } from 'reactstrap'
import { FileImageService } from '../../../common/services/file-image.service'
import { PreSignedUploadUrl, SpecificationFile } from '../../../types'
import { useDownloadFile } from '../../use-download-file'
import { FilePathService } from '../../../common/services/file-path.service'

interface Props {
  file: SpecificationFile
}

export const FileRow: FC<Props> = ({ file }) => {
  const getPresignedUrl = useMemo(() => {
    if (file) {
      return {
        url: `${process.env.REACT_APP_APIDOMAIN}/aam/downloadUrl`,
        params: {
          path: FilePathService.encode(file.absolutePath),
          bucket: FilePathService.encode(file.bucket),
        },
      }
    }
    return null
  }, [file])

  const getPresignedUrlTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getPresignedUrl)

  const download = useDownloadFile({
    fileUrl: `${getPresignedUrlTask.result?.data.url}`,
    fileName: file?.fileName || '',
  })

  useAsyncRun(getPresignedUrlTask.result && download)

  const handleDownload = () => {
    getPresignedUrlTask.start()
  }

  const inProgress = useMemo(() => {
    return (
      ((getPresignedUrlTask.pending && getPresignedUrlTask.started) ||
        (download.pending && download.started)) &&
      !download.result
    )
  }, [getPresignedUrlTask, download])

  return (
    <div className="file-row">
      <FontAwesomeIcon
        className={'file-image'}
        icon={FileImageService.getFileImageByFileType(file.fileType)}
      />
      <span className="file-name">{file.fileName}</span>
      <Button
        className="download-button"
        color="primary"
        onClick={handleDownload}
      >
        {inProgress ? (
          <Spinner className="mr-2" size="sm" />
        ) : (
          <FontAwesomeIcon
            className="download-icon"
            icon={faCloudDownloadAlt}
          />
        )}
        Download
      </Button>
    </div>
  )
}
