import React from 'react'
import { DownloadButton } from '../../../common/components/download-button/download-button'
import { OfQualDownloadButton } from './ofqual-download'
import { isCAAdmin, isOCR, isCambridgeInternational } from '../../../util'
import { usePresignedDownload } from '../../../common/hooks/use-presigned-download'
import { useAuth0 } from '../../../auth'

export const OfqualDownloadReport = () => {
  const { user } = useAuth0()

  const {
    pending: ofQualTagsReportPending,
    download: ofQualTagsReportDownload,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/ofqual-tags-report/url`,
    fileName: `ofqual_tags.csv`,
  })

  const {
    pending: ofQualCentreReportPending,
    download: ofQualCentreReportDownload,
  } = usePresignedDownload({
    url: `${process.env.REACT_APP_APIDOMAIN}/ofqual-centre-report/url`,
    fileName: `ofqual_centre.csv`,
  })

  if (!(user && isCAAdmin(user))) return <></>

  return (
    <>
      {isOCR() && (
        <>
          <DownloadButton
            text="Download Ofqual Centre report"
            download={ofQualCentreReportDownload}
            pending={ofQualCentreReportPending}
          />
          <DownloadButton
            text="Download Ofqual TAG report"
            download={ofQualTagsReportDownload}
            pending={ofQualTagsReportPending}
          />
        </>
      )}
      {isCambridgeInternational() && <OfQualDownloadButton />}
    </>
  )
}
