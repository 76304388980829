import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import axios, { AxiosResponse } from 'axios'
import React, { FC, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import { Button, Spinner } from 'reactstrap'
import { useDownloadFile } from '../../../components/use-download-file'
import { PreSignedUploadUrl } from '../../../types'
import './download-template-button.scss'

export const DownloadTemplateButton: FC<{
  centreId: string
  id: string
  fileName: string
}> = ({ centreId, id, fileName }) => {
  const getSyllabusTemplateUrl = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${id}/candidates/csv`,
    }),
    [centreId, id]
  )

  const getSyllabusTemplateTask = useAsyncTaskAxios<
    AxiosResponse<PreSignedUploadUrl>
  >(axios, getSyllabusTemplateUrl)

  const download = useDownloadFile({
    fileUrl: `${getSyllabusTemplateTask.result?.data.url}`,
    fileName,
  })

  useAsyncRun(getSyllabusTemplateTask.result && download)

  const downloadPending = useMemo(
    () =>
      (getSyllabusTemplateTask.pending && getSyllabusTemplateTask.started) ||
      (getSyllabusTemplateTask.result != null &&
        download.pending &&
        download.started),
    [download, getSyllabusTemplateTask]
  )

  const handleDownload = (e: React.MouseEvent<any, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    getSyllabusTemplateTask.start()
  }

  return (
    <Button
      color="primary"
      className="text-uppercase download-template-button"
      onClick={(e) => handleDownload(e)}
    >
      {downloadPending ? (
        <Spinner size="sm" className="mr-2" />
      ) : (
        <FontAwesomeIcon icon={faArrowToBottom} className="mr-2 font-normal" />
      )}
      <span className="font-normal">Download</span>
    </Button>
  )
}
