import React, { useMemo } from 'react'
import { Button, Spinner } from 'reactstrap'
import { useDownloadFile } from '../../use-download-file'
import { useAsyncRun, useAsyncTaskAxios } from 'react-hooks-async'
import axios, { AxiosResponse } from 'axios'
import { PreSignedUploadUrl } from '../../../types'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const OfQualDownloadButton: React.FC<any> = (): JSX.Element => {
  const getQualUrl = useMemo(
    () => ({
      url: `${process.env.REACT_APP_APIDOMAIN}/ofqual-report/url`,
    }),
    []
  )

  const getQualUrlTask = useAsyncTaskAxios<AxiosResponse<PreSignedUploadUrl>>(
    axios,
    getQualUrl
  )

  const download = useDownloadFile({
    fileUrl: `${getQualUrlTask.result?.data.url}`,
    fileName: 'ofqual.csv',
  })

  useAsyncRun(getQualUrlTask.result && download)

  const downloadPending = useMemo(() => {
    return (
      (getQualUrlTask.pending && getQualUrlTask.started) ||
      (getQualUrlTask.result !== null && download.pending && download.started)
    )
  }, [
    download,
    getQualUrlTask.result,
    getQualUrlTask.pending,
    getQualUrlTask.started,
    download.pending,
    download.started,
  ])

  return (
    <Button color="success" onClick={() => getQualUrlTask.start()}>
      {!downloadPending && (
        <FontAwesomeIcon icon={faArrowToBottom} fixedWidth />
      )}
      {downloadPending && <Spinner size="sm" className="mr-2" />}
      <div>Download Ofqual report</div>
    </Button>
  )
}
