import React, { FC, useEffect, useMemo, useState } from 'react'
import { BackButtonProvider } from '../common/context/back-button.context'
import axios from 'axios'
import { SpecificationFiles } from './specification-files/specification-files'
import { SpecificationToQualification } from './specifications-to-qualification/specifications-to-qualification'
import { SubjectPage } from './subject-page/subject-page'
import { Switch } from 'react-router'
import { NavBar } from './navbar'
import { HomePage } from './home'
import PrivateRoute from './private-route/private-route'
import { CentreSyllabusPageWithRouteAndExpiration } from './centre-syllabus-page/centre-syllabus-page'
import {
  ManageTeachersPageWithExpiration,
  ManageTeachersPageWithRouteAndExpiration,
} from './pages/manage-teachers'
import {
  ManageAAMPageWithExpiration,
  ManageAAMPageWithRouteAndExpiration,
} from './pages/manage-aam'
import {
  ManageSignOffPageWithExpiration,
  ManageSignOffPageWithRouteAndExpiration,
} from './pages/manage-sign-off'

import { isAdmin, isCAAdmin, isOCR, ROLES } from '../util'
import { useAuth0 } from '../auth'
import { UnitCodes } from './unit-codes/unit-codes'

import { useConfig, useLoadSystemAvailabilityConfig } from './use-remote-config'
import HoldingPage from './holding-page/holding-page'
import ServerErrorHoldingPage from './holding-page/server-error-holding-page'
import { HelpAndContact } from './help-and-contact'
import { useTracking } from './use-tracking'
import { ManageAdminsPageWithRouteAndExpiration } from './pages/manage-admins'
import { AdditionalAssessmentMaterial } from './additional-assessment-material/additional-assessment-material'
import { CambridgeAdminControls } from './ca-admin-controls/ca-admin-controls'
import { isFuture, isPast } from 'date-fns'
import { CAAdminBannerWithRoute } from './ca-admin-banner'
import { RouterService } from '../common/services/router-service'
import Route from './route'
import { useExpiryPage } from './use-expiry-page'
import { ExpiryHoldingPage } from './holding-page/expiry-holding-page'
import { NotFound } from './not-found'

export const RoutesWithHeader: FC = (): JSX.Element => {
  useTracking(process.env.REACT_APP_GA_TRACKING_CODE)

  const { user, currentCentre, isAuthenticated } = useAuth0()
  const { config, systemAvailabilityConfigLoaded } = useConfig()
  const loadSystemAvailabilityConfig = useLoadSystemAvailabilityConfig()

  const [aamAvailabilityExpired, captureAvailabilityExpired] = useExpiryPage()
  const [serverError, setServerError] = useState(null)

  useEffect(() => {
    axios.interceptors.response.use(undefined, function (error) {
      if (`${error?.response?.status}`.startsWith('5')) {
        // 5xx error code
        setServerError(error)
      }
      return Promise.reject(error)
    })
  }, [])

  const rules = useMemo(() => {
    const {
      captureAvailableFrom,
      captureAvailableTo,
      aamAvailableTo,
      aamAvailableFrom,
    } = config

    const canSubmitGrades =
      config.submitGradesAvailable &&
      config?.submitGradesAvailable > 0 &&
      isPast(config?.submitGradesAvailable || 0)

    const aam =
      (isOCR() && user && isCAAdmin(user)) ||
      (aamAvailableTo &&
        aamAvailableFrom &&
        isPast(aamAvailableFrom) &&
        isFuture(aamAvailableTo)) ||
      !systemAvailabilityConfigLoaded

    const centres = RouterService.isCentresRouteAvailable(
      config,
      systemAvailabilityConfigLoaded,
      captureAvailableTo,
      captureAvailableFrom
    )

    return {
      aam,
      manageAAM: isOCR() || !isAuthenticated,
      centres,
      manage:
        canSubmitGrades ||
        isOCR() ||
        (user && (isAdmin(user) || isCAAdmin(user))),
    }
  }, [config, isAuthenticated, user])

  useEffect(() => {
    if (isAuthenticated) {
      loadSystemAvailabilityConfig()
    }
  }, [isAuthenticated])

  return (
    <div style={{ marginBottom: '5rem' }}>
      <BackButtonProvider>
        <NavBar />
        {!serverError && config.siteEnabled && (
          <Switch>
            <Route exact path="/">
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            </Route>

            {rules.aam && [
              <Route key="aam-1" exact path="/aam">
                <PrivateRoute>{<AdditionalAssessmentMaterial />}</PrivateRoute>
              </Route>,
              <Route
                key="aam-2"
                exact
                path="/aam/:qualification"
                render={(props) => (
                  <PrivateRoute>
                    <SpecificationToQualification
                      qualification={props.match.params.qualification}
                    />
                  </PrivateRoute>
                )}
              />,
              <Route
                key="aam-3"
                exact
                path="/aam/:qualification/:specification"
                render={(props) => (
                  <PrivateRoute>
                    <UnitCodes
                      qualification={props.match.params.qualification}
                      //todo need refactor
                      specification={props.match.params.specification.replaceAll(
                        '%2F',
                        '/'
                      )}
                    />
                  </PrivateRoute>
                )}
              />,
              <Route
                key="aam-4"
                exact
                path="/aam/:qualification/:specification/:unitCode"
                render={(props) => (
                  <PrivateRoute>
                    <SpecificationFiles
                      qualification={props.match.params.qualification}
                      //todo need refactor
                      specification={props.match.params.specification.replaceAll(
                        '%2F',
                        '/'
                      )}
                      unitCode={props.match.params.unitCode.replaceAll(
                        '%2F',
                        '/'
                      )}
                    />
                  </PrivateRoute>
                )}
              />,
            ]}

            <Route exact path="/centres">
              <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
                <CambridgeAdminControls />
              </PrivateRoute>
            </Route>
            {!rules.centres && user && isCAAdmin(user) && (
              <Route exact path="/centres/:id">
                <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
                  <CAAdminBannerWithRoute />
                </PrivateRoute>
              </Route>
            )}
            {rules.centres && [
              <Route key="centres-1" exact path="/centres/:id">
                <PrivateRoute>
                  <CentreSyllabusPageWithRouteAndExpiration
                    isExpired={captureAvailabilityExpired}
                  />
                </PrivateRoute>
              </Route>,
              <Route
                key="centres-2"
                exact
                path="/centres/:id/syllabus/:syllabusId"
              >
                <PrivateRoute>
                  <SubjectPage />
                </PrivateRoute>
              </Route>,
            ]}

            {rules.manageAAM && [
              <Route key="manageaam-1" exact path="/manage/aam">
                <PrivateRoute roles={[ROLES.Admin]}>
                  {currentCentre && (
                    <ManageAAMPageWithExpiration
                      centreId={currentCentre}
                      isExpired={aamAvailabilityExpired}
                    />
                  )}
                </PrivateRoute>
              </Route>,
              <Route key="manageaam-2" exact path="/centres/:id/manage/aam">
                <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
                  {user && (
                    <ManageAAMPageWithRouteAndExpiration
                      isExpired={aamAvailabilityExpired}
                    />
                  )}
                </PrivateRoute>
              </Route>,
            ]}

            {rules.manage && [
              <Route key="manage-1" exact path="/manage/teachers">
                <PrivateRoute roles={[ROLES.Admin]}>
                  {currentCentre && (
                    <ManageTeachersPageWithExpiration
                      centreId={currentCentre}
                      isExpired={captureAvailabilityExpired}
                    />
                  )}
                </PrivateRoute>
              </Route>,
              <Route key="manage-2" exact path="/manage/signoff">
                <PrivateRoute roles={[ROLES.Admin]}>
                  <ManageSignOffPageWithExpiration
                    centreId={currentCentre}
                    isExpired={captureAvailabilityExpired}
                  />
                </PrivateRoute>
              </Route>,

              <Route key="manage-3" exact path="/centres/:id/manage/teachers">
                <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
                  {user && (
                    <ManageTeachersPageWithRouteAndExpiration
                      isExpired={captureAvailabilityExpired}
                    />
                  )}
                </PrivateRoute>
              </Route>,
              <Route key="manage-4" exact path="/centres/:id/manage/signoff">
                <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
                  {user && (
                    <ManageSignOffPageWithRouteAndExpiration
                      isExpired={captureAvailabilityExpired}
                    />
                  )}
                </PrivateRoute>
              </Route>,
              <Route key="manage-5" exact path="/centres/:id/manage/admins">
                <PrivateRoute roles={[ROLES.QA_Super, ROLES.CA_Admin]}>
                  {user && (
                    <ManageAdminsPageWithRouteAndExpiration
                      isExpired={captureAvailabilityExpired}
                    />
                  )}
                </PrivateRoute>
              </Route>,
            ]}

            <Route exact path="/help-and-contact">
              <PrivateRoute>
                <HelpAndContact />
              </PrivateRoute>
            </Route>

            {(aamAvailabilityExpired || captureAvailabilityExpired) && (
              <Route component={ExpiryHoldingPage} />
            )}

            <Route component={NotFound} />
          </Switch>
        )}
        {!serverError && !config.siteEnabled && <HoldingPage />}
        {serverError && <ServerErrorHoldingPage />}
      </BackButtonProvider>
    </div>
  )
}
