import React, { useEffect, useMemo, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { ICandidateUpdateExtended } from '../../components/candidates-list/constants'
import { isOCR } from '../../util'

import {
  CentreSyllabus,
  InstructionMaterialDTO,
  SyllabusCandidate,
} from '../../types'
import { ProjectModal } from '../modal/modal'
import { RsModalHeader } from '../modal/subcomponents/modal-header'
import { RsModalBody } from '../modal/subcomponents/modal-body'
import ReactGA from 'react-ga'

import { CandidatesList } from './candidates-list'
import { Label } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { BulkSetXGradeModal } from '../subject-page/components/bulk-set-x-grade-modal/bulk-x-grade-modal'
import { useAsyncTaskAxios } from 'react-hooks-async'
import { DataSource } from '../../common/enum/data-source'
import {getSubTitle} from "../../common/services/centre-syllabus-service";

interface ICandidatesListContainer {
  gradeFilter: string
  candidates: SyllabusCandidate[]
  centreId: string
  syllabusId: string
  lastUpdated: number
  // ** feature/ISPR-1517 hide ties and ranks **
  // allowTies?: boolean
  showErrors: boolean
  viewOnly: boolean
  toggleShowErrors: (newShow: boolean) => void
  approvalDisabled: (status: boolean) => void
  downloadCallback: () => void
  pathSuite: number
  syllabus: CentreSyllabus
}

export const CandidatesListContainer: React.FC<ICandidatesListContainer> = ({
  gradeFilter,
  showErrors,
  toggleShowErrors,
  candidates,
  centreId,
  syllabusId,
  viewOnly,
  // ** feature/ISPR-1517 hide ties and ranks **
  // allowTies,
  approvalDisabled,
  downloadCallback,
  syllabus,
}): JSX.Element => {
  const [isSetXGradeModalOpen, setIsSetXGradeModalOpen] = useState(false)

  const candidatesBaseUrl = useMemo(
    () =>
      `${process.env.REACT_APP_APIDOMAIN}/centres/${centreId}/syllabuses/${syllabusId}/candidates`,
    [centreId, syllabusId]
  )
  const [cachedCandidates, updateCachedCandidates] = useState<
    SyllabusCandidate[]
  >(candidates)
  const [lastUpdate, updateLastUpdate] = useState<number>(
    Number(syllabus?.lastUpdated)
  )
  const [candidatesPatchingProgress, setCandidatesPatchingProgress] = useState<{
    [key: string]: string | undefined
  }>({})
  const [changeRequest, setChangeRequest] = useState<any | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [pathwaysUpdating, setPathwaysUpdating] = useState<boolean>(false)

  useEffect(() => {
    updateCachedCandidates(candidates)
  }, [candidates])

  const availablePaths = useMemo(() => syllabus.paths || [], [syllabus.paths])

  const patchCandidate = useMemo(
    () => (
      // ** feature/ISPR-1517 hide ties and ranks **
      // update: { id: string; grade: string; rank?: number },
      update: ICandidateUpdateExtended,
      cb?: (good: boolean) => void
    ) => {
      ;(async () => {
        // ** feature/ISPR-1517 hide ties and ranks **
        // const { id, grade, rank } = update
        const { id, grade, pathwayOne, pathwayTwo } = update
        if (candidatesPatchingProgress[id] === 'pending') return

        setCandidatesPatchingProgress((tasks) => ({
          ...tasks,
          [id]: 'pending',
        }))

        const cachedCandidate:
          | SyllabusCandidate
          | undefined = cachedCandidates.find(
          (candidate) => candidate.id === update.id
        )

        if (
          cachedCandidate?.pathwayOne !== update.pathwayOne ||
          cachedCandidate?.pathwayTwo !== update.pathwayTwo
        ) {
          setPathwaysUpdating(true)
        }

        try {
          ReactGA.event({
            category: 'Grade Submission',
            action: 'Patch Candidate',
            label: syllabusId,
          })

          const { status, data } = await axios({
            method: 'patch',
            url: `${candidatesBaseUrl}/${encodeURIComponent(id)}`,
            data: {
              id,
              grade,
              pathwayOne,
              pathwayTwo,
              // ** feature/ISPR-1517 hide ties and ranks **
              // rank:
              //   cachedCandidate?.grade !== grade ? undefined : rank,
            },
          })

          if (status !== 200 && status !== 205) {
            throw new Error()
          }

          let updates = [data]
          if (status === 205) {
            const res = await axios.get(candidatesBaseUrl)
            if (res.status === 200) {
              updates = res.data
            }
          }

          updateCachedCandidates((cachedCandidates) =>
            cachedCandidates.map((it: any) => {
              const c = updates.find((itt: any) => itt.id === it.id)
              return !c
                ? it
                : {
                    ...it,
                    grade: c.grade,
                    pathwayOne: c.pathwayOne,
                    pathwayTwo: c.pathwayTwo,
                    // ** feature/ISPR-1517 hide ties and ranks **
                    // rank: c.rank,
                  }
            })
          )
          updateLastUpdate(Date.now())
          cb && cb(true)
          setCandidatesPatchingProgress((tasks) => ({
            ...tasks,
            [id]: undefined,
          }))
          setPathwaysUpdating(false)
        } catch (err) {
          setCandidatesPatchingProgress((tasks) => ({
            ...tasks,
            [id]: `error-${cachedCandidate?.grade !== grade ? 'g' : 'r'}`,
          }))
          cb && cb(false)
        }
      })()
    },
    [
      candidatesBaseUrl,
      cachedCandidates,
      candidatesPatchingProgress,
      syllabusId,
    ]
  )

  const onPatchRequest = useMemo(
    () => (
      // ** feature/ISPR-1517 hide ties and ranks **
      // update: { id: string; grade: string; rank?: number },
      update: ICandidateUpdateExtended,
      cb?: (good: boolean) => void
    ) => {
      if (viewOnly) return
      const candidate = cachedCandidates.find((c) => c.id === update.id)
      if (!candidate) return

      // ** feature/ISPR-1517 hide ties and ranks **
      // if (
      //   candidate.grade &&
      //   candidate.grade !== update.grade &&
      //   candidate.rank
      // ) {

      if (candidate.grade && candidate.grade !== update.grade) {
        handleChangeGrade(candidate, update, cb)
      } else {
        patchCandidate(update, cb)
      }
    },
    [cachedCandidates, setChangeRequest, setShowModal, patchCandidate, viewOnly]
  )

  const handleChangeGrade = (
    candidate: SyllabusCandidate,
    update: { id: string; grade?: string },
    cb?: (good: boolean) => void
  ) => {
    const changeRequest = {
      candidate,
      update,
      cb,
    }

    if (isOCR()) {
      setChangeRequest(null)
      patchCandidate(changeRequest.update, changeRequest.cb)
    } else {
      setChangeRequest(changeRequest)
      setShowModal(true)
    }
  }

  const isShowSetXGradeButton = useMemo(() => {
    const isCandidatesWithoutGradeExist = cachedCandidates.some(
      (candidate) => !candidate.grade
    )
    const isSupportedDataSource =
      syllabus?.dataSource === DataSource.CAMS ||
      syllabus?.dataSource === DataSource.OEPS

    return isCandidatesWithoutGradeExist && isOCR() && isSupportedDataSource
  }, [cachedCandidates, syllabus])

  const clearPatchingErrors = useMemo(
    () => (ids?: string[]) => {
      setCandidatesPatchingProgress((x) =>
        Object.entries(x).reduce(
          (acc, [key, val = '']) => ({
            ...acc,
            ...(val.startsWith('error') && (!ids || ids.includes(key))
              ? {}
              : { [key]: val }),
          }),
          {}
        )
      )
    },
    [setCandidatesPatchingProgress]
  )

  const candidatesWithoutGrades = useMemo(
    () => cachedCandidates.filter((candidate) => !candidate.grade).length,
    [cachedCandidates]
  )

  useEffect(() => {
    clearPatchingErrors()
  }, [clearPatchingErrors, gradeFilter])

  const bulkSetXGradeUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/bulk-set-x-grade/centre/${centreId}/syllabus/${syllabusId}`,
      method: 'patch',
    }
  }, [])

  const bulkSetXGradeTask = useAsyncTaskAxios<
    AxiosResponse<InstructionMaterialDTO>
  >(axios, bulkSetXGradeUrl)

  useEffect(() => {
    if (bulkSetXGradeTask.result) {
      setIsSetXGradeModalOpen(false)
      updateCachedCandidates((cachedCandidates) => {
        return cachedCandidates.map((candidate) => {
          return candidate.grade ? candidate : { ...candidate, grade: 'X' }
        })
      })
    }
  }, [bulkSetXGradeTask.result])

  const title = useMemo(() => syllabus.syllabusName || syllabus.syllabusCode, [
    syllabus
  ])
  const subTitle = useMemo(
      () => getSubTitle(syllabus.qualification, syllabus.syllabusCode, syllabus.qualLevel, syllabus.dataSource),
      [syllabus]
  )

  return (
    <div>
      <CandidatesList
        approvalDisabled={approvalDisabled}
        gradeFilter={gradeFilter}
        showErrors={showErrors}
        toggleShowErrors={toggleShowErrors}
        // ** feature/ISPR-1517 hide ties and ranks **
        // allowTies={allowTies}
        candidates={cachedCandidates}
        candidatesPatchingProgress={candidatesPatchingProgress}
        clearPatchingErrors={clearPatchingErrors}
        patchCandidate={onPatchRequest}
        downloadCallback={downloadCallback}
        lastUpdated={lastUpdate}
        upToDate={
          Object.values(candidatesPatchingProgress).filter(
            (x) => x === 'pending'
          ).length === 0
        }
        viewOnly={viewOnly}
        syllabus={syllabus}
        availablePaths={availablePaths}
        pathwaysUpdating={pathwaysUpdating}
        openSetXGradeModal={() => setIsSetXGradeModalOpen(true)}
        isShowSetXGradeButton={isShowSetXGradeButton}
      />

      <ProjectModal
        className="candidate-grade-change-modal"
        centered
        isOpen={showModal && changeRequest !== null}
      >
        <RsModalHeader
          toggle={() => setShowModal(false)}
          className="bg-white px-5 pt-5 pb-0"
        >
          <h4
            className="font-weight-bold text-primary mb-0"
            style={{ fontSize: '22px' }}
          >
            Changing grade for a candidate
          </h4>
          <h5 className="mb-0 mt-25">
            <span className="font-weight-bold">
              {changeRequest?.candidate?.name}{' '}
              {changeRequest?.candidate?.surname}
            </span>
            <span className="ml-1">{`(${changeRequest?.candidate.candidateNumber})`}</span>
          </h5>
        </RsModalHeader>
        <RsModalBody className="pt-0 px-5 pb-5">
          <div className="mt-4">
            <Label
              className="mb-0 candidate-grade d-inline-flex justify-content-center align-items-center"
              style={{ width: '3rem', height: '3rem' }}
            >
              {changeRequest?.candidate?.grade}
            </Label>
            <FontAwesomeIcon
              className="mx-42 d-inline-flex align-items-center"
              style={{ width: '20px', height: '20px' }}
              size="2x"
              icon={faArrowRight}
            />
            <Label
              className="mb-0 candidate-grade d-inline-flex justify-content-center align-items-center"
              style={{ width: '3rem', height: '3rem' }}
            >
              {changeRequest?.update?.grade}
            </Label>
          </div>
          <Label className="mb-0 mt-4 font-larger" style={{ maxWidth: '90%' }}>
            {/** feature/ISPR-1517 hide ties and ranks **/}
            {/* You are trying to change this candidate's grade. This will remove
            their current rank order. You will need to provide a new rank order
            for the new grade. */}
            You are trying to change this candidate's grade.
          </Label>

          {/** feature/ISPR-1517 hide ties and ranks **/}
          {/* <Label
            className="mb-0 mt-4 font-larger font-weight-bold"
            style={{ maxWidth: '90%' }}
          >
            Please note the rank orders for candidates in this grade will also
            be updated based on their existing order.
          </Label> */}

          <div className="mt-55">
            <Label
              data-testid={'grade-change-cancel'}
              className="bg-lightButNotTooLight text-secondary font-weight-bold rounded px-35 py-25 mb-0 pointer"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Label>
            <Label
              data-testid={'grade-change-continue'}
              className="bg-primary text-white font-weight-bold rounded px-35 py-25 ml-3 mb-0 pointer"
              onClick={() => {
                setShowModal(false)
                setChangeRequest(null)
                patchCandidate(changeRequest?.update, changeRequest?.cb)
              }}
            >
              Continue
            </Label>
          </div>
        </RsModalBody>
      </ProjectModal>
      <BulkSetXGradeModal
        header={title}
        subheader={subTitle}
        ancillery={`${cachedCandidates.length} candidates`}
        showSpinner={bulkSetXGradeTask.pending && bulkSetXGradeTask.started}
        isOpen={isSetXGradeModalOpen}
        onClose={() => setIsSetXGradeModalOpen(false)}
        candidatesWithoutGrade={candidatesWithoutGrades}
        bulkSetXGrade={() => bulkSetXGradeTask.start()}
      />
    </div>
  )
}
