import React from 'react'
import { ManagementPage } from './management-page'
import { ROLES } from '../../util'
import { withCentreId, withExpiration } from '../../enrichers'

let INFO_DESCRIPTION = <></>

if (process.env.REACT_APP_BU === 'gss') {
  INFO_DESCRIPTION = (
    <>
      The Head of Centre is responsible for reviewing and approving teacher
      assessed grades before submitting them to OCR. They will also need to
      complete a Head of Centre declaration.
      <br />
      If your current Head of Centre is not correct, not available or needs to
      delegate this role, remove your current Head of Centre below and then add
      the name and email address for their replacement. They will receive a
      confirmation email with a verification link. They will need to click the
      link to verify their account before they can review and approve data.
    </>
  )
}

export const ManageSignOffPage: React.FC<{ centreId: string }> = ({
  centreId,
}): JSX.Element => (
  <ManagementPage
    title="Set up Head of Centre"
    centreId={centreId}
    description={INFO_DESCRIPTION}
    buttonText="Add Head of Centre"
    emptyListMessage="No head of centre has been assigned"
    role={ROLES.HOC}
    currentUsersLabel="Current Head of Centre"
  />
)

export const ManageSignOffPageWithRoute = withCentreId(ManageSignOffPage)
export const ManageSignOffPageWithExpiration = withExpiration(ManageSignOffPage)
export const ManageSignOffPageWithRouteAndExpiration = withExpiration(
  ManageSignOffPageWithRoute
)
